import request from '@/utils/request'

export function AddBbs(params) {
  return request({
    url: '/api/BBS/Add',
    data: params,
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function getBbsList(params) {
  return request({
    url: '/api/BBS/List',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function getMyBbsList(params) {
  return request({
    url: '/api/BBS/MyList',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function UpdateHRDepartmentId(params) {
  return request({
    url: '/api/BBS/UpdateHRDepartmentId',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
///api/BBS/Delete
export function deleteBbs(params) {
  return request({
    url: '/api/BBS/Delete',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}


// 获取详情
export function getBbsInfo(id) {

  return request({
    url: `/api/BBS?para=${id}`
  })
}
export function GetUserDepartmentPathItemList(){
  return request({
    url: `/api/BBS/GetUserDepartmentPathItemList`
  })
}

//  /api/BBS/TypeList
export function getBBSTypeList(params) {
  return request({
    url: '/api/BBS/TypeList',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function getBBSTopicList(params) {
  return request({
    url: '/api/BBS/TopicList',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
///api/BBS/GetTopicName
export function getBBSTopicName(id){
  return request({
    url: `/api/BBS/GetTopicName?id=${id}`
  })
}


export function getBBSTopicS7List(params) {
  return request({
    url: '/api/BBS/TopicS7List',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
///api/BBS/GetTopicName
export function getBBSTopicS7Name(id){
  return request({
    url: `/api/BBS/GetTopicName?id=${id}`
  })
}

export function getActivityType(){
  return request({
    url: `/api/BBS/getActivityType`
  })
}

