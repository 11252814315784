import request from '@/utils/request'


///api/CourseOffline/AddFromH5
export function AddFromH5(params) {
    return request({
        url: '/api/CourseOffline/AddFromH5',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}

export function getCourseOfflineList(params) {
    return request({
        url: '/api/CourseOffline/ListByUser',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}
export function getListSignUp(params) {
    return request({
        url: '/api/CourseOffline/ListSignUp',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}
export function getListSignIn(params) {
    return request({
        url: '/api/CourseOffline/ListSignIn',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}

export function getListSignInAndComment(params) {
    return request({
        url: '/api/CourseOffline/ListSignInAndComment',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}

export function getListNotComment(params) {
    return request({
        url: '/api/CourseOffline/ListNotComment',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}

export function getListNotCommentForSchool(params) { ///api/CourseOffline/ListNotCommentForSchool
    return request({
        url: '/api/CourseOffline/ListNotCommentForSchool',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}


export function getCourseOfflineListBeforeSingUp(params) {
    return request({
        url: '/api/CourseOffline/ListBeforeSingUp',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}

// /api/CourseOffline

// 获取文章详情
export function getCourseOffline (id) {
    return request({
      url: `/api/CourseOffline?id=${id}`
    })
  }
export function getTeacher (id) {
    return request({
      url: `/api/Teacher?para=${id}`
    })
}


  // 获取文章详情
export function getCourseOfflineGetScore (id) {
    return request({
      url: `/api/CourseOffline/GetScore?id=${id}`
    })
  }

  export function PageHasSignInByUser(params) {
    return request({
        url: '/api/CourseOffline/PageHasSignInByUser',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}

export function PageTeachByUser(params) {
    return request({
        url: '/api/CourseOffline/PageTeachByUser',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}

export function CourseOfflineOnlineLearnByUser(params) {
    return request({
        url: '/api/CourseOffline/CourseOfflineOnlineLearnByUser',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错 /api/CourseOffline/List
        },

    })
}