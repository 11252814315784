import request from '@/utils/request'

export function getMyCertificate(para) {
    return request.post("/api/UserCertificate/List", para);
  }
  export function getUserCertificate(userid,para) {
    return request.post("/api/UserCertificate/UserList?uid="+userid, para);
  }

  export function getMyCertificateDetail(para) {
    return request.get("/api/UserCertificate/Get?para="+para);
  }