<template>
  <div  >
    <!-- <van-row>
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="page.filterData.typeId"
            :options="pclassOption"
            @change="onSearch"
          />
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search
          v-model="page.filterData.title"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        />
      </van-col>
    </van-row>

    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 0 ? '#9d1d22' : ''"
          @click="changeSorterType(0)"
          >默认</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 1 ? '#9d1d22' : ''"
          @click="changeSorterType(1)"
          >最新</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 3 ? '#9d1d22' : ''"
          @click="changeSorterType(3)"
          >最热</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 2 ? '#9d1d22' : ''"
          @click="changeSorterType(2)"
          >最早</van-tag
        >
      </van-col>
    </van-cell>
    <van-cell
      v-if="
        page.filterData.topicId > 0 ||
        page.filterData.productId != '00000000-0000-0000-0000-000000000000'
      "
    >
      <van-col span="24">
        <van-tag
          v-if="page.filterData.topicId > 0"
          closeable
          round
          size="large"
          color="#9d1d22"
          @close="closetopic"
        >
          {{ topicName }}
        </van-tag>

        <van-tag
          v-if="
            page.filterData.productId != '00000000-0000-0000-0000-000000000000'
          "
          closeable
          round
          size="large"
          color="#9d1d22"
          @close="closeproduct"
        >
          {{ productName }}
        </van-tag>
      </van-col>
    </van-cell> -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text=""
      >
        <van-cell-group v-for="item in articles" :key="item.id.toString()">
          <van-cell @click="toDetail(item.id.toString())" :border="false">
            <van-col span="3">
              <van-image
                style="float: left"
                round
                width="41px"
                height="41px"
                fit="fill"
                :src="item.headImgurl"
               
              />
            </van-col>

            <van-col span="21">
              <div
                style="
                  float: left;

                  width: 100%;
                "
              >
                <a style="color: #069; font-size: 16px; font-weight: bold">
                  {{ item.trueName }}
                </a>
               <userIcon :iconstring="item.icon" ></userIcon>
              </div>

              <div style="float: left; color: #696969">
                {{ item.creationTime | relTime }}
              </div>
              <div style="float: left; color: #696969; margin-left: 13px">
                浏览{{ item.readCount }}人次
              </div>
            </van-col>

            <van-col span="2"></van-col>
            <van-col span="22">
              <div>
                <h3 style="display: inline-block">{{ item.title }}</h3>

                &nbsp;
                <van-tag
                  v-if="item.audioUrl != undefined && item.audioUrl.length > 5"
                  mark
                  plain
                  color="#9d1d22"
                  >有音频
                </van-tag>
                &nbsp;&nbsp;
                <van-tag
                  v-if=" item.videoUrl != undefined && item.videoUrl.length > 5"
                  mark
                  plain
                  color="#9d1d22"
                  >有视频
                </van-tag>
              </div>
              <div class="acontent van-multi-ellipsis--200">
                <div class="bbscontent" v-html="item.detailsHtml"></div>
                <div
                  class="showqw"
                  style="
                    position: absolute;
                    height: 30px;
                    color: rgb(0, 102, 153);
                    font-size: 16px;
                    font-weight: bold;
                    width: 100%;
                    bottom: 0px;
                    background: white;
                  "
                >
                  点击查看全文
                </div>
              </div>
            </van-col>
          </van-cell>
          <van-cell>
            <!--话题-->
            <van-col span="1"></van-col>
            <van-col span="22">
              <div style="margin-top: 10px">
                <div
                  style="display: inline-block"
                  v-if="item.typeName != undefined && item.typeName.length > 0"
                  @click="toType(item)"
                >
                  <van-tag
                    color="rgb(255 242 219)"
                    text-color="#9d1d22"
                    size="large"
                    >#{{ item.typeName }}#
                  </van-tag>

                  <span style="width: 10px; display: inline-block"></span>
                </div>
                <div
                  style="display: inline-block"
                  v-if="
                    item.topicName != undefined && item.topicName.length > 0
                  "
                  @click="toTopic(item)"
                >
                  <van-tag
                    size="large"
                    color="rgb(255 242 219)"
                    text-color="#9d1d22"
                    >#{{ item.topicName }}#
                  </van-tag>
                  <span style="width: 10px; display: inline-block"></span>
                </div>

<div
                  style="display: inline-block"
                  v-if="
                    item.leaderCommenCount != undefined && item.leaderCommenCount > 0
                  "
                 
                >
                  <van-tag
                    size="large"
                    color="rgb(255 242 219)"
                    text-color="#9d1d22"
                    ># 高管回复#
                  </van-tag>
                  <span style="width: 10px; display: inline-block"></span>
                </div>

                <van-tag
                  @click="toProduct(item)"
                  v-if="
                    item.productName != undefined && item.productName.length > 0
                  "
                  size="large"
                  color="rgb(255 242 219)"
                  text-color="#9d1d22"
                  >{{ item.productName }}
                </van-tag>
              </div>
            </van-col>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <div style="margin:30px ">
     <van-button
     v-if="total>3"
            type="info"
            size="large"
            round
            block
            @click="tobbsList()"
            >查看更多</van-button
          >
            <p  v-if="total==0" class="empty__description">暂无内容</p> 
    </div>
  </div>
</template>

<script>
import { getBbsList, getBBSTypeList, getBBSTopicName } from "@/api/bbs";
import { getProductName } from "@/api/product";

import UserIcon from "../components/userIcon";

//const key = "zn-history";
export default {
  name: "bbslist",
  props: {
    type: String,
    userId: String,
  },
  data() {
    return {
      scroll: 0,
      refreshing: false,
      pclassOption: [{ text: "全部", value: 0 }],

      title: "中酿心声",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      total:0,
      topicName: "",
      productName: "",
      page: {
        fromType: 1,
        sorterType: 0,
        current: 1,
        pageSize: 3,
        filterData: {
          typeId: 0,
          productId: "00000000-0000-0000-0000-000000000000",
          topicId: 0,
          title: "",
        },
      },
    };
  },
  components: {
       UserIcon
  },
 watch:{
  
     userId(val, oldVal) {
      //普通的watch监听
      if (val != oldVal) {
         this.page.filterData.creatorId=val;
          this. onRefresh();
      }
    },
    
 },
  
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
     onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },

      tobbsList() {
      this.$router.push({ path: "/bbs",
       query: { uid: this.userId,reload:true }
        });
    },
    async initData() {
      this.page.sorterType = 0;
      this.page.filterData.creatorId=this.userId;
      let productId = this.$route.query.productId;
      if (productId === undefined) {
        productId = "00000000-0000-0000-0000-000000000000";
      }
      this.page.filterData.productId = productId;

      if (productId != "00000000-0000-0000-0000-000000000000") {
        let nameReuslt = await getProductName(productId);
        this.productName = nameReuslt.data.data;
      }

      let topicId = this.$route.query.topicid;

      if (topicId === undefined) {
        topicId = 0;
      }
      topicId = parseInt(topicId);
      this.page.filterData.topicId = topicId;

      if (topicId > 0) {
        let nameReuslt = await getBBSTopicName(topicId);

        this.topicName = nameReuslt.data.data;
      }

      let typeId = this.$route.query.typeid;

      if (typeId === undefined) {
        typeId = 0;
      }
      typeId = parseInt(typeId);
      this.page.filterData.typeId = typeId;

      let pClassList = await getBBSTypeList();
      this.pclassOption = [{ text: "全部", value: 0 }];

      pClassList.data.data.map((x) =>
        this.pclassOption.push({ text: x.typeName, value: x.id })
      );

      this.page.filterData.typeId = typeId;

      //let type = this.$route.query.type;
    },
    onSearch() {
      this.articles = [];
      this.total=0;
      this.finished = false;
      this.page.current = 1;
      this.page.pageSize = 3;
      this.onLoad();
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
    toDetail(id) {
      try {
       this.$parent.beforeOut();
        this.$router.push({
          path: "/bbs/detail",
          query: { para: id, type: this.page.filterData.typeId,back:true },
        });
      } catch (error) {
        console.log(error);
      }
    },
     toUserHomePage(id) {
      try {
        let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
        this.scroll = wrapperScrollTop;
        this.$router.push({
          path: "/userHomepage",
          query: { para: id },
        });
      } catch (error) {
        console.log(error);
      }
    },

    toadd() {
      this.$router.push({ path: "/bbs/add" });
    },

    async onLoad() {
      this.upLoading = true;
      let aresult = await getBbsList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.total=aresult.data.total;
      this.upLoading = false;
      this.finished = true;
      // if (this.page.current * this.page.pageSize < aresult.data.total) {
      //   this.page.current++;
      // } else {
      //   this.finished = true;
      // }
    },
    toType(item) {
      if (this.page.filterData.typeId != item.typeId) {
        this.page.filterData.typeId = item.typeId;
        this.onSearch();
      }
    },
    toTopic(item) {
      if (this.page.filterData.topicId != item.topicId) {
        this.topicName = item.topicName;
        this.page.filterData.topicId = item.topicId;
        this.onSearch();
      }
    },
    toProduct(item) {
      if (this.page.filterData.productId != item.productId) {
        this.productName = item.productName;
        this.page.filterData.productId = item.productId;
        this.onSearch();
      }
    },
    closetopic() {
      this.page.filterData.topicId = 0;
      this.onSearch();
    },
    closeproduct() {
      this.page.filterData.productId = "00000000-0000-0000-0000-000000000000";
      this.onSearch();
    },

    showQW() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((element) => {
        if (element.childNodes.length == 2) {
          if (element.childNodes[0].clientHeight > 300) {
            element.childNodes[1].style.display = "block";
          } else {
            element.childNodes[1].style.display = "none";
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 30px;
}
.container {
  //height: 100%;
  //overflow-y: auto;
  //box-sizing: border-box;
}
.empty__description {
  text-align: center;
  padding: 0 60px;
  margin: 20px 0 5px 0;
  color: #969799;
  font-size: 14px;
  line-height: 20px;
}

.bbscontent {
  height: 100%;
}

.acontent {
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;

  /deep/ ol {
    list-style: decimal !important;
    margin-left: 25px;
  }
  /deep/ ol li {
    list-style-position: outside;
  }
  /deep/ img {
    max-width: 33% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
