<template>
  <div class="container" ref="wrapper">
    <van-loading class="loading" color="#9d1d22" text-color="#9d1d22" v-if="loading" size="24px">加载中...</van-loading>
    <div v-if="loading == false">
      <div class="user-profile">
        <div class="info">
          <van-icon name="arrow-left" size="25px" @click="$router.back()" />&nbsp;&nbsp;
          <van-image round :src="userInfo.headImgurl" />
          <h3 class="name">
            {{ userInfo.trueName }} | {{ userInfo.department }}|
            {{ userInfo.grade }}
            <br />
            <br />
            <userIcon :iconstring="userInfo.icon" iconSize="L"></userIcon>
          </h3>
        </div>
        <div style="margin-left: 45px" v-if="!userInfo.isMe">
          <van-tag v-if="!userInfo.hasFollow" round size="large" type="primary" @click="addUserFollower">关注</van-tag>
          <van-tag v-if="userInfo.hasFollow" round size="large" type="primary" @click="cancelUserFollower">取消关注</van-tag>

          <van-tag round style="margin-left: 45px" size="large" type="primary" @click="sendFlower">送花</van-tag>
        </div>
        <div>
          <van-row>
            <van-col span="4">
              <div class="countStr">心声</div>
              <div class="count">{{ userInfo.bbsCount }}</div>
            </van-col>
            <van-col span="4">
              <div class="countStr">总结</div>
              <div class="count">{{ userInfo.workLogCount }}</div>
            </van-col>
            <van-col span="4">
              <div class="countStr">粉丝</div>
              <div class="count">{{ userInfo.followerCount }}</div>
            </van-col>
            <van-col span="4">
              <div class="countStr">获赞</div>
              <div class="count">{{ userInfo.likeCount }}</div>
            </van-col>
            <van-col span="4">
              <div class="countStr">鲜花</div>
              <div class="count">{{ flowerAcceptCount }}</div>
            </van-col>
            <van-col span="4">
              <div class="countStr">能量值</div>
              <div class="count">{{ userInfo.score1 }}</div>
            </van-col>
          </van-row>
        </div>
      </div>
      <!-- <van-cell
      ><van-col span="23" offset="1"><h2>荣誉奖项</h2></van-col></van-cell
    >
    <p class="empty__description">暂无内容</p> -->
      <van-cell><van-col span="23" offset="1">
          <h2>授课听课</h2>
        </van-col>
        <div>
          <van-row>
            <van-col span="12" v-if="userInfo.teachCount > 0" @click="toCourse(userInfo.id)">
              <div class="countStr">授课</div>
              <div class="count countClick">
                {{ userInfo.teachCount }}
              </div>
            </van-col>
            <van-col span="12" v-if="userInfo.teachCount == 0">
              <div class="countStr">授课</div>
              <div class="count">{{ userInfo.teachCount }}</div>
            </van-col>
            <van-col span="12">
              <div class="countStr">听课</div>
              <div class="count">{{ userInfo.learnCount }}</div>
            </van-col>
          </van-row>
        </div>
      </van-cell>
      <!-- <courseofflineItmebyUser
      title="最近授课"
      :userId="userId"
      :GetListData="PageTeachByUser"
    ></courseofflineItmebyUser>
    <courseofflineItmebyUser
      title="最近听课"
      :userId="userId"
      :GetListData="PageHasSignInByUser"
    ></courseofflineItmebyUser>
    <courseofflineItmebyUser
      title="最近回看"
      :userId="userId"
      :GetListData="CourseOfflineOnlineLearnByUser"
    ></courseofflineItmebyUser> -->
      <van-cell>
        <van-row>
          <van-col span="18">
            <h2 style="padding: 2px 1px 5px 18px">获得证书</h2>
          </van-col>
          <van-col span="6" @click="toUsercertificate()">
            <div style="float: right; padding: 8px 20px 0px 0px" v-if="userCertificateList.length>0" >
              更多<van-icon name="arrow" /></div>
          </van-col>
        </van-row>


        <div class="van-clearfix image-list">
          <div class="image-item" v-for="item in userCertificateList" :key="item.id.toString()">

            <div class="image-wrapper">
              <van-image lazy-load :src="item.imageUrl">
              </van-image>
              <div class="image-description">
                <h3 style="display: inline-block">{{ item.title }}</h3>
                <h4>{{ item.remark ? item.remark : item.creationTime }}</h4>
              </div>
            </div>
          </div>
        </div>

        <p  v-if="userCertificateList.length==0" class="empty__description">暂无内容</p> 

      </van-cell>
      <van-cell v-show="!showBbsTitel"><van-col span="23" offset="1">
          <h2>心声</h2>
        </van-col></van-cell>

      <bbsList :userId="userId"></bbsList>
    </div>
  </div>
</template>

<script>
import {
  getUserHomePage,
  addUserFollower,
  cancelUserFollower,
} from "@/api/user";
import { getUserCertificate } from "@/api/userCertificate";

import { SendFlower, AcceptCount, HasCount } from "@/api/flower";
import {
  PageTeachByUser,
  CourseOfflineOnlineLearnByUser,
  PageHasSignInByUser,
} from "@/api/courseoffline";

//import store from '@/store';
import { Toast } from "vant";

import UserIcon from "../components/userIcon";
import BbsList from "../components/bbslist";
//import CourseofflineItmebyUser from "../components/CourseofflineItmebyUser";

export default {
  name: "userHomepage",
  components: {
    UserIcon,
    BbsList,
    //CourseofflineItmebyUser,
  },
  data() {
    return {
      loading: true,
      userInfo: {},
      userCertificateList: [],
      userId: "",
      showfollower: true,
      showBbsTitel: false,
      flowerHasCount: 0,
      flowerAcceptCount: 0,
      sendFlowerPara: {
        id: 0,
        amount: 1,
        acceptUserId: "",
      },
      userCertificatepagePara: {
        current: 1,
        pageSize: 3,
        fromType: 1,
        filterData: {},
      },
    };
  },
  activated() {
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (from.path == "/") {
        this.scroll = 0;
      }
      if (to.path == "/userHomepage") {
        if (to.query.para != this.userId) {
          this.loading = true;
          this.userId = to.query.para;
          this.getUser();
        }
      }
    },
  },

  created() {
    this.userId = this.$route.query.para;
    this.getUser();
  },

  methods: {
    async PageTeachByUser(para) {
      var aresult = await PageTeachByUser(para);
      return aresult.data.data;
    },
    toCourse(id) {
      this.$router.push({
        path: "/course/list",
        query: { uid: id },
      });
    },
    async CourseOfflineOnlineLearnByUser(para) {
      var aresult = await CourseOfflineOnlineLearnByUser(para);
      return aresult.data.data;
    },
    async PageHasSignInByUser(para) {
      var aresult = await PageHasSignInByUser(para);
      return aresult.data.data;
    },
    async addUserFollower() {
      var aresult = await addUserFollower(this.userId);
      if (aresult.data.success) {
        this.userInfo.hasFollow = true;
        Toast("关注成功");
      }
    },

    async cancelUserFollower() {
      var aresult = await cancelUserFollower(this.userId);
      if (aresult.data.success) {
        Toast("取消关注成功");
        this.userInfo.hasFollow = false;
      }
    },
    async sendFlower() {
      this.sendFlowerPara.acceptUserId = this.userId;
      var aresult = await SendFlower(this.sendFlowerPara);
      if (aresult.data.success) {
        Toast("送花成功");
        this.flowerAcceptCount++
      } else {
        Toast(aresult.data.errorMessage);
      }
    },
    async getUser() {
      let para = this.$route.query.para;
      var aresult = await getUserHomePage(para);
      this.userInfo = aresult.data.data;
      var acceptReulst = await AcceptCount(this.userInfo.id);
      this.flowerAcceptCount = acceptReulst.data.data;

      var hasReulst = await HasCount();
      this.flowerHasCount = hasReulst.data.data;
      this.loading = false;

      var userCertificateListReuslt = await getUserCertificate(para, this.userCertificatepagePara)
      this.userCertificateList = userCertificateListReuslt.data.data;

    },
    beforeOut() {
      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;
    },


    myscroll(para) {
      if (this.showBbsTitel != para.isFixed) {
        this.showBbsTitel = para.isFixed;
      }
    },
    toDetail(id) {
      this.$router.push({
        path: "/user/mycertificatedetail",
        query: { para: id },
      });
    },
    toUsercertificate() {
      this.$router.push({
        path: "/user/usercertificate",
        query: { para: this.$route.query.para, username: this.userInfo.trueName },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.empty__description {
  text-align: center;
  padding: 0 60px;
  margin: 20px 0 5px 0;
  color: #969799;
  font-size: 14px;
  line-height: 20px;
}

.image-list {
  display: flex;
  flex-wrap: wrap;
}

.image-item {
  width: calc(33.33% - 10px);
  margin: 5px;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.image-wrapper /deep/ img {
  display: block;
  width: 100%;
  height: 15vh;
  /* 设置图片高度为视口高度的 20% */
  object-fit: cover;
}

.image-description {
  text-align: center;
}


.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.loading {
  text-align: center;
}

.countStr {
  text-align: center;
  font-size: 16px;
  margin: 20px 0 5px 0;
}

.countClick {
  color: rgb(0, 102, 153);
}

.count {
  text-align: center;
  font-size: 18px;
  margin: 20px 0 5px 0;
  font-weight: bold;
}

.empty__description {
  text-align: center;
  padding: 0 60px;
  margin: 20px 0 5px 0;
  color: #969799;
  font-size: 14px;
  line-height: 20px;
}

.icon_num_red {
  margin-left: 8px;
  margin-right: 5px;
  font-weight: normal;
  background: #df3e3e;
  display: inline-block;
  width: 10px;
  height: 10px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 16px;
  *line-height: 15px;
  border-radius: 50%;
  font-family: Arial;
}

.user {
  &-profile {
    width: 100%;
    height: 220px;
    display: block;
    background: rgb(157, 29, 34);
    color: #fff;

    .info {
      display: flex;
      padding: 10px 3px 5px 3px;
      align-items: center;

      .van-image {
        width: 64px;
        height: 64px;
      }

      .name {
        font-size: 16px;
        font-weight: normal;
        margin-left: 10px;
      }

      .van-tag {
        background: #fff;
        color: #3296fa;
      }
    }

    p {
      margin: 0;
      text-align: center;
    }
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
      padding-bottom: 5px;
    }
  }



}
</style>
