<template>
  <span :class="iconSize == 'M' ? 'sizeM' : 'sizeL'">
    <!-- <a @click.stop="clickhandle()">
      <i
        title="达人"
        style="
          font-style: normal;
          font-weight: normal;
          width: 14px;
          height: 14px;
          margin-left: 3px;
          vertical-align: -2px;
          display: inline-block;
          background-position: 0 -50px;
          background-image: url(/image/icon.png);
          background-repeat: no-repeat;
        "
      ></i>
    </a> -->
    <!--入职时间等级-->

    <span :key="n" v-for="n in myyearLevels" :class="'kmlv lv' + n" title="">
      <span v-if="n >= 10"  class="no"><b :class="'d' + m" :key="m" v-for="m in  Array.from(String(n), Number)" ></b></span>
    </span>

    <!-- <span
      :key="n"
      v-for="n in myyearLevels"
      class="qz_qzone_lv qz_qzone_lv_3 qz_qzone_no_2"
      title=""
    >
      <span class="no"><b :class="'d' + n"></b></span>
    </span> -->
  </span>
</template>
<script>

export default {
  props: {
    iconstring: {
      type: String,
      default: "",
    },
    iconSize: {
      type: String,
      default: "M",
    },
  },
  data() {
    return {
      yearLevels: [],
    };
  },
  computed: {
    myyearLevels: function () {

      for (let i = 30; i >= 1; i--) {
        if (this.iconstring.indexOf("year" + i) > -1) {
          return [i]
        }

      }
      return []
    },
 
  },
  created() {
    //100级
   
    this.yearLevels = Array.from(Array(100), (v, k) => k);
  },
  methods: {
   
  },
};
</script>

<style lang="less" scoped>
.sizeM {
  /deep/.kmlv {
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
    background-repeat: no-repeat;

  }

  /deep/.lv1 {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -5px 3px;
  }

  /deep/.lv2 {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -33px 3px;
  }

  /deep/.lv3 {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -63px 3px;
  }

  /deep/.lv4 {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -90px 3px;
  }

  /deep/.lv5 {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -120px 3px;
  }

  /deep/.lv6 {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -150px 3px;
  }

  /deep/.lv7 {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -176px 3px;
  }

  /deep/.lv8 {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -206px 3px;
  }

  /deep/.lv9 {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -236px 3px;
  }

  /deep/.lv10,.lv11,.lv12,.lv13 ,.lv14 ,.lv15 ,.lv16 ,.lv17 ,.lv18 ,.lv19 ,.lv20 ,.lv21 ,.lv22 ,.lv23 ,.lv24,.lv25,.lv26,.lv27,.lv28,.lv29,.lv30  {
    background-image: url(/image/kmlv.png);
    background-size: 296px 24px;
    background-position: -264px 3px;
  }

  
  /deep/.no {
  position: absolute;
  left: 3px;
  bottom: 0;
  width: 21px;
  line-height: 7px;
  height: 9px;
  _overflow: hidden;
}
  /deep/ .no b {
  display: inline-block;
  width: 8px;
  background-repeat: no-repeat;
  height: 7px;
  margin-right: -1px;
  vertical-align: top;
}

  /deep/ .no .d1 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -126px 0;
}

  /deep/ .no .d2 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -144px 0;
}

  /deep/ .no .d3 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -162px 0;
}

  /deep/ .no .d4 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -180px 0;
}

  /deep/ .no .d5 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -198px 0;
}

  /deep/ .no .d6 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -216px 0;
}

  /deep/ .no .d7 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -234px 0;
}

  /deep/ .no .d8 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -252px 0;
}

  /deep/ .no .d9 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -270px 0;
}

  /deep/ .no .d0 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -288px 0;
}
}

.sizeL {
  /deep/.kmlv {
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;
    background-repeat: no-repeat;

  }

  /deep/.lv1 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -10px 0px;
  }

  /deep/.lv2 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -46px 3px;
  }

  /deep/.lv3 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -83px 3px;
  }

  /deep/.lv4 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -121px 3px;
  }

  /deep/.lv5 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -167px 3px;
  }

  /deep/.lv6 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -207px 3px;
  }

  /deep/.lv7 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -249px 3px;
  }

  /deep/.lv8 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -289px 3px;
  }

  /deep/.lv9 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -330px 3px;
  }

  /deep/.lv10,.lv11,.lv12,.lv13 ,.lv14 ,.lv15 ,.lv16 ,.lv17 ,.lv18 ,.lv19 ,.lv20 ,.lv21 ,.lv22 ,.lv23 ,.lv24,.lv25,.lv26,.lv27,.lv28,.lv29,.lv30 {
    background-image: url(/image/kmlv.png);
    background-size: 420px 40px;
    background-position: -370px 3px;
  }

  /deep/.no {
  position: absolute;
  left: 13px;
  bottom: 0;
  width: 21px;
  line-height: 7px;
  height: 13px;
  _overflow: hidden;
}
  /deep/ .no b {
  display: inline-block;
  width: 8px;
  background-repeat: no-repeat;
  height: 7px;
  margin-right: -1px;
  vertical-align: top;
}

  /deep/ .no .d1 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -126px 0;
}

  /deep/ .no .d2 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -144px 0;
}

  /deep/ .no .d3 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -162px 0;
}

  /deep/ .no .d4 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -180px 0;
}

  /deep/ .no .d5 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -198px 0;
}

  /deep/ .no .d6 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -216px 0;
}

  /deep/ .no .d7 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -234px 0;
}

  /deep/ .no .d8 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -252px 0;
}

  /deep/ .no .d9 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -270px 0;
}

  /deep/ .no .d0 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -288px 0;
}
}

.qz_qzone_no_2 {
  margin-right: 8px;
}

.qz_qzone_lv {
  display: inline-block;
  width: 16px;
  background-repeat: no-repeat;
  height: 16px;
  position: relative;
  margin-right: 2px;
}

.qz_qzone_lv_3 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -36px 0;
}

.qz_qzone_lv .no {
  position: absolute;
  left: 10px;
  bottom: 0;
  width: 21px;
  line-height: 7px;
  height: 7px;
  _overflow: hidden;
}

.qz_qzone_lv .no b {
  display: inline-block;
  width: 8px;
  background-repeat: no-repeat;
  height: 7px;
  margin-right: -1px;
  vertical-align: top;
}

.qz_qzone_lv .no .d1 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -126px 0;
}

.qz_qzone_lv .no .d2 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -144px 0;
}

.qz_qzone_lv .no .d3 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -162px 0;
}

.qz_qzone_lv .no .d4 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -180px 0;
}

.qz_qzone_lv .no .d5 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -198px 0;
}

.qz_qzone_lv .no .d6 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -216px 0;
}

.qz_qzone_lv .no .d7 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -234px 0;
}

.qz_qzone_lv .no .d8 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -252px 0;
}

.qz_qzone_lv .no .d9 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -270px 0;
}

.qz_qzone_lv .no .d0 {
  background-image: url(/image/lv.png);
  background-size: 304px 16px;
  background-position: -288px 0;
}

</style>              