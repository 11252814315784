import request from "@/utils/request";


export const SendFlower = (obj) => {
    return request.post("/api/flower/SendFlower", obj);
}

export function HasCount() {
    return request({
        url: '/api/flower/HasCount'
    })
}

export function AcceptCount(id) {
    return request({
        url: `/api/flower/AcceptCount?id=${id}`
    })
}